import React from 'react';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';

const SmallProjectCard = ({
  project,
  setIsModal,
  setProject,
  isProject2024
}) => {
  const { t } = useTranslation();
  const { language } = React.useContext(I18nextContext);

  return (
    <>
      {isProject2024 ? (
        <div className="project-small-ctn-2024">
          <div className="left-ctn">
            <h3>{project.name}</h3>
            <div style={{ color: '#0F5575' }}>{project.authorsFor2024}</div>
          </div>
          <button
            className="right-ctn img-button"
            onClick={() => {
              setIsModal(true);
              setProject(project);
            }}>
            {language !== 'pl' && project.img_en !== undefined
              ? project.img_en
              : project.img}
            <div className="img-button-readmore">
              <p>{t`previous-editions.read-more`}</p>
            </div>
          </button>
        </div>
      ) : (
        <div className="project-small-ctn">
          <div className="left-ctn">
            <h3>{project.name}</h3>
            <h4>{t`previous-editions.authors-title`}</h4>
            {project.authors}
          </div>
          <button
            className="right-ctn img-button"
            onClick={() => {
              setIsModal(true);
              setProject(project);
            }}>
            {language !== 'pl' && project.img_en !== undefined
              ? project.img_en
              : project.img}
            <div className="img-button-readmore">
              <p>{t`previous-editions.read-more`}</p>
            </div>
          </button>
        </div>
      )}
    </>
  );
};

export default SmallProjectCard;
